<template>
  <section class="content">
    <h1 slot="header">Rootservers</h1>
    <p>Rootserver</p>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ViewRootservers',
  setup() {
    //
  },
})
</script>
